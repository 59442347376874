<template>
  <div class="contentH">
      <el-result icon="info" title="页面跑丢了" subTitle="页面突然迷路了">
      <template slot="extra">
        <el-button type="primary" size="medium" @click="goHome">返回首页</el-button>
      </template>
    </el-result>
    <div style="width: 1200px; margin: 0 auto">
      <OpenClass :freeClassList="freeClassList" />
    </div>
  </div>
</template>

<script>
import OpenClass from "@/components/public/OpenClass/OpenClass"; // 免费公开课

export default {
  components:{
    OpenClass
  },
  data(){
    return {
      freeClassList:[]
    }
  },
  created(){
    this.getFreeClassList();

  },
  methods:{
    goHome(){
      this.$router.push("/")
    },
    // 获取免费课列表
    getFreeClassList() {
      this.Api.Login.getFreeClassList().then((res) => {
        if (res.status == 200) {
          this.freeClassList = res.data;
        }
      });
    },
  }
}
</script>

<style scoped>
.contentH {
  min-height: calc(100vh - 345.08px);
}
</style>
